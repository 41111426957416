
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Input' })
export class Input extends Vue {
  @Prop({ type: String, required: false, default: 'text' })
  public readonly type!: string

  @Prop({ type: String, required: false, default: '' })
  public readonly placeholder!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly required!: boolean

  @Prop({ type: Boolean, required: false })
  public readonly minimumRadius?: boolean

  @Prop({ type: String, required: false })
  public readonly prependIcon?: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isFluid!: boolean

  @Prop({ type: Array, required: false })
  public readonly error?: string[]

  @PropSync('model', { type: String, required: true })
  public _model!: string

  public get hasError (): boolean {
    return Array.isArray(this.error) && this.error.length > 0
  }

  public onBlur (): void {
    this.$emit('blur')
  }
}
export default Input
