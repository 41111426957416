import { Route } from 'vue-router'
import { TranslateResult } from 'vue-i18n'

import { Breadcrumb } from '@/dsl/atoms/Breadcrumbs'

export const getBreadcrumbsFactoryForRoute = (route: Route, label?: string, t?: (key: string) => TranslateResult): Breadcrumb[] => {
    return [
        {
            key: 'home',
            label: t ? t('home').toString() : 'home',
            route: { name: 'root.main' }
        },
        {
            key: route.name ?? 'unknown',
            label: label ? label : route.name ? route.name.split('.')[1] : 'unknown',
            route: { name: route.name ?? 'root.main' }
        }
    ]
}