
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'DrawerInner' })
export class DrawerInner extends Vue {
  @Prop({ type: Function, required: true })
  public close!: CallableFunction
}

export default DrawerInner
