import { LoggerFunctions } from '../general'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const logger = (): LoggerFunctions => {
  return {
    log: (message: string, level: 'debug' | 'log' = 'debug'): void => {
      console[level](message)
    }
  }
}