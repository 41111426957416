import en from '@/assets/locales/en'
import pl from '@/assets/locales/pl'
import ru from '@/assets/locales/ru'
// import uz from '@/assets/locales/pl'


/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const messages = {
  en: { ...en },
  pl: { ...pl },
  ru: { ...ru }
}