import { VueConstructor } from 'vue'
import { injectable } from 'inversify'
import { BehaviorSubject, Observable } from 'rxjs'

import { AnyObject } from '../../general'

import { IModal, ModalName, ModalOptions, ModalRegister, ModalState } from './contracts'

/**
 *
 * **Modal service**
 *
 * @description - Service responsible for registering, displaying modals.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@injectable()
export class ModalService implements IModal {
  protected register: ModalRegister = {}
  protected state: BehaviorSubject<ModalState> = new BehaviorSubject<ModalState>({})

  public get state$ (): Observable<ModalState> {
    return this.state.asObservable()
  }

  /**
   * @inheritDoc
   */
  public close (): void {
    this.state.next({
      open: false
    })
  }
  /**
   * @inheritDoc
   */
  public registerAll (register: ModalRegister): void {
    this.register = { ...this.register, ...register }
  }

  /**
   * @inheritDoc
   */
  public show<P extends AnyObject> (name: ModalName, payload: P, options?: ModalOptions): void {
    const modal = this.findModalByName(name)

    this.state.next({
      open: true,
      modal,
      payload,
      options
    })
  }

  /**
   * Determines the modal in the register and returns, otherwise throws an Exception
   */
  private findModalByName (name: ModalName): VueConstructor {
    if (name in this.register) {
      return this.register[name]
    }

    throw new Error(`Requested modal is not registered! Requested: [${name}]`)
  }
}