import { AnyObject } from '@/core/general/contracts'

/**
 * Model
 *
 * @description - Basic representation of the Model class.
 */
export abstract class Model<M> {
  protected properties: M

  constructor(payload: M) {
    this.properties = payload
    Object.assign(this, this.properties)
  }

  public get<K extends keyof M>(key: K): M[K] {
    return this.properties[key];
  }

  public static hydrate<IModel> (payload: AnyObject): IModel {
    // @ts-ignore
    return new this(payload) as IModel
  }

  public toObject (): M {
    return this.properties
  }

}
