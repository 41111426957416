export default {
  readMore: 'Czytaj więcej',
  filters: 'Filtry',
  home: 'Strona główna',
  pwaPrompt: {
    install: 'Pobierz App',
    close: 'Zamknij'
  },
  eventsList: {
    title: 'Wydarzenia'
  },
  location: 'Lokacja',
  event: {
    dateTime: 'Data',
    location: 'Lokalizacja',
    price: 'Cena',
    category: 'Kategoria',
    description: 'Opis',
    buyTicket: 'Kup bilet',
    showOnMap: 'Pokaż na mapie',
    peopleResponded: 'zainteresowani',
  },
  filtersDrawer: {
    label: 'Filtry',
    reset: 'Reset',
    showResults: 'Pokaż wyniki',
    filter: {
      categories: 'Kategorie',
      date: 'Data',
      location: 'Lokalizacje'
    }
  },
  contactUs: {
    title: 'Contact Us',
    description: 'Provide quick explanation of purpose of contacting',
    form: {
      sender: 'Email or phone number',
      subject: 'Name, nickname, etc..',
      content: 'Theme...',
      submit: 'Submit'
    },
    isSent: {
      description: 'Your request has been successfully. Thank you for your time and interest.',
      submit: 'Close'
    }
  },
  searchBar: {
    placeholder: 'Szukaj...',
    results: 'Wyniki wyszukiwania',
    notFound: 'Nie znaleziono dla `{phrase}`',
    showAll: 'Pokaż wszystkie',
    resultsFor: 'Wyniki wyszukiwanie dla: '
  },
  navbar: {
    contact: 'CONTACT US',
    lastEvents: 'Ostatnie wydarzenia',
    savedEvents: 'Zapisane wydarzenia',
    search: 'Zacznij zapisywać dane zdarzenia (np. tytuł)...',
    maps: 'Mapa wydarzeń'
  },
  footer: {
    events: {
      title: 'Wydarzenia',
      children: {
        saved: 'Polubione wydarzenia',
        past: 'Rzesły wydarzenia'
      }
    },
    contact: {
      title: 'Kontakt',
      children: {
        offer: 'Mam ofertę / pomysł'
      }
    },
    socials: {
      title: 'Social media',
      children: {
        instagram: 'Instagram',
        facebook: 'Facebook',
        tiktok: 'TikTok',
      }
    },
    'terms-and-privacy': {
      title: 'Regulamin & Polika prywatności',
      children: {
        'privacy-policy': 'Polityka prywatności',
      }
    }
  },
  noEventsBanner: {
    textOneTemp: 'Przepraszamy, nie znaleziono żadnych wydarzeń dla podanego miasta',
    textOne: 'Przepraszamy, nie znaleziono żadnych wydarzeń dla ',
    textTwo: 'Proszę spróbować wybrać inne miasto za pomocą '
  },
  relatedEvents: 'Podobne wydarzenia',
  savedEvents: 'Ulubione wydarzenia',
  pastEvents: 'Stare wydarzenia',
  openInGoogleMaps: 'Otwórz w Google Maps',
  save: 'Zapisz',
  collections: 'Grupy wydarzeń',
  events: 'wydarzeń',
  open: 'Otwórz',
  locale: 'Język',
  loadMore: 'Załaduj więcej',
  locales: {
    EN: 'English',
    RU: 'Русский',
    PL: 'Polski'
  }
}