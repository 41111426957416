
import { Component, Vue, Prop } from 'vue-property-decorator'

import { BreadcrumbsProps } from './Breadcrumbs.contracts'

@Component({ name: 'Breadcrumbs' })
export class Breadcrumbs extends Vue {
  /**
   * @see BreadcrumbsProps.breadcrumbs
   */
  @Prop({ type: Array, required: true })
  public readonly breadcrumbs!: BreadcrumbsProps['breadcrumbs']
}
export default Breadcrumbs
