
import { Component, Prop, Vue } from 'vue-property-decorator'

import { resolveTheme } from '../../helpers'
import { defaultThemeRegistry, Theme } from '../../assets/config'

import { BadgeProps } from './Badge.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Badge' })
export class Badge extends Vue {
  /**
   * @see BadgeProps.dismissable
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly dismissable!: BadgeProps['dismissable']

  /**
   * @see BadgeProps.title
   */
  @Prop({ type: String, required: true })
  public readonly title!: BadgeProps['title']

  /**
   * @see BadgeProps.theme
   */
  @Prop({ type: String, required: false, default: Theme.Primary })
  protected readonly theme!: BadgeProps['theme']

  /**
   * Determines the theme class for the badge.
   */
  public get resolvedThemeClass(): string {
    return resolveTheme(this.theme, defaultThemeRegistry, Theme.Primary)
  }

  public onDismiss (): void {
    this.$emit('dismiss')
  }
}
export default Badge
