import { AnyObject } from '@/core/general'

/**
 * Cookie string parser.
 *
 * @return - Object like structure that represents cookies.
 */
export const parseCookie = (cookie?: string, decodeValue= true): AnyObject | undefined => {
  if (!cookie) {
    return
  }

  return cookie
    .split(';')
    .map(v => v.split('='))
    .reduce<AnyObject>((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeValue ? decodeURIComponent(v[1].trim()) : v[1].trim()
      return acc
    }, {} as AnyObject)
}
