import { ModalRegister } from '@/core/services/modal'

import { ContactUsModal } from '../components/organisms/ContactUsModal'
import { CitySelectorModal } from '../components/organisms/CitySelectorModal'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export enum RootModuleModalsCollection {
  ContactUs = 'contact_us',
  CityChoices = 'city_choices'
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const rootModuleModalsRegistry: ModalRegister = {
  [RootModuleModalsCollection.ContactUs]: ContactUsModal,
  [RootModuleModalsCollection.CityChoices]: CitySelectorModal
}