import { AxiosResponse } from 'axios'

import {
  AnyObject,
  IRepository,
  MappingSkeleton,
  Pageable,
  Model
} from '../general'
import { IHttpConnector } from '../connectors/http'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export class Repository<T extends Model<AnyObject>, D> implements IRepository<T> {
  private readonly httpConnector: IHttpConnector
  protected map: MappingSkeleton = {}

  constructor (connector: IHttpConnector) {
    this.httpConnector = connector
  }

  /**
   * @inheritDoc
   */
  public async call (name: string, action: string, params?: AnyObject, body?: AnyObject): Promise<AxiosResponse> {
    return await this.httpConnector.call(name, action, params, body)
  }

  /**
   * @inheritDoc
   */
  public toMap<T> (payload: AnyObject, model: new (payload?: any) => T): T {
    let mapped = payload

    if (Object.keys(this.map).length === 0) {
      return Object.assign(new model(), mapped)
    }

    mapped = Object.entries(this.map).reduce((acc, [mKey, oKey]) => {
      return {
        ...acc,
        [mKey]: typeof oKey === 'string' ? payload[oKey as unknown as string] : oKey(payload)
      }
    }, {})

    return new model(mapped)
  }

  /**
   * @inheritDoc
   */
  public toMapCollection<D extends Model<AnyObject> = T>
    (payload: AnyObject,
     model: new (payload: any) => D,
     considerPagination: boolean = true,
    ): Pageable<D> | Array<D> {
      if (considerPagination) {
        return {
          items: payload.data.results.map((entry: AnyObject) => this.toMap<D>(entry, model)),
          count: payload.data.count,
          previousPage: payload.data.previous,
          nextPage: payload.data.next
        } as Pageable<D>
      }

      return payload.data.results.map((entry: AnyObject) => this.toMap<D>(entry, model))
  }
}
