
import { Component, Prop, Vue } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'
import { logger } from '@/core'

import { IRootService, RootServiceType } from '../../../services/root'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component({ name: 'ContactUsModal' })
export class ContactUsModal extends Vue {
  @Prop({ type: Function, required: true })
  public close!: CallableFunction

  protected readonly rootService = this.$container.get<IRootService>(RootServiceType)

  public isSent: boolean = false

  public formData: AnyObject = {
    sender: '',
    subject: '',
    content: ''
}

  /**
   * Submits the form.
   */
  public async submit (): Promise<void> {
    try {
      await this.rootService.sendMail(
        this.formData.sender,
        this.formData.subject,
        this.formData.content
      )

      this.isSent = true
    } catch (e) {
      logger().log((e as Error).message)
    }
  }
}
export default ContactUsModal
