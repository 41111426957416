import { Event, EventModel } from './event'

import { Model } from '@/core/general'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface EventsGroup {
  title: string
  description?: string
  lang: string
  events?: EventModel[]
  image?: string
  city?: string
  url?: string
  eventsCount?: string
  keywords: string
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface IEventsGroup {
  /**
   * Returns the event models.
   */
  getEvents (): EventModel[]
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export class EventsGroupModel extends Model<EventsGroup> implements IEventsGroup {
  /**
   * @inheritDoc
   */
  public getEvents (): EventModel[] {
    return [...(this.properties['events'] || [])].map((event) => {
      return EventModel.hydrate<EventModel>(event)
    })
  }
}