import { AxiosResponse } from 'axios'

import { AdditionalRequestParams, IMiddleware, logger } from '@/core'
import { IResource } from '@/core/general'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export class LoaderMiddleware implements IMiddleware {

  /**
   * @inheritDoc
   */
  public beforeRequest (resource: IResource): AdditionalRequestParams {
    // logger().log(`Requested: `, 'log')
    // logger().log(resource, 'log')

    return {
      headers: {}
    }
  }

  /**
   * @inheritDoc
   */ 
  public afterRequest (response: AxiosResponse): void {
    // logger().log(`Resource response:`, 'log')
    // logger().log(response)
  }
}

export const loaderMiddleware = new LoaderMiddleware()
