import { BootstrappedApp } from '@/core/bootstrap/bootstrapper.contracts'
import { Bootstrap, logger } from '@/core'

import { modules } from '@/config/modules'

import { IRootService, RootServiceType } from '@/modules/root/services/root'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export async function createSSRApp (context?: any): Promise<BootstrappedApp> {
  const app = new Bootstrap(process.env.VUE_APP_NAME, modules, context)
  const bootedApp = await app.boot()

  const container = app.getContainer()!

  try {
    const rootService = container.get<IRootService>(RootServiceType)
    const client = context.req ? (context.req.headers['x-forwarded-for'] ?? '89.64.71.241') : '89.64.71.241'
    await rootService.init(client)
  } catch (err) {
    logger().log((err as Error).message, 'log')
  }

  return {
    ...bootedApp,
  }
}