import { injectable } from 'inversify'

import { Repository } from '@/core/abstract'
import { AnyObject, Pageable } from '@/core/general'

import { Event, EventModel, EventsGroupModel } from '../models'
import { eventMappingSkeleton } from '../maps'
import { IEventsRepository } from '../contracts'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@injectable()
export class EventsRepository extends Repository<EventModel, Event> implements IEventsRepository {
  protected map = eventMappingSkeleton

  /**
   * @inheritDoc
   */
  public async fetchEvent (slug: string): Promise<EventModel> {
    const response = await this.call('EventResources', 'fetchEvent', { slug })
    return this.toMap(response.data, EventModel)
  }

  /**
   * @inheritDoc
   */
  public async fetchEvents (page = 1, pageSize = 8, query?: AnyObject): Promise<Pageable<EventModel>> {
    const response = await this.call('EventResources', 'fetchEvents', {
      page,
      'page_size': pageSize,
      'not_ended': true,
      ...query } )
    return this.toMapCollection(response, EventModel) as Pageable<EventModel>
  }

  /**
   * @inheritDoc
   */
  public async fetchGroupedEvents (slug: string): Promise<EventsGroupModel> {
    const response = await this.call('EventResources', 'fetchGroupedEvents', { slug })

    return EventsGroupModel.hydrate<EventsGroupModel>({
      title: response.data.title,
      description: response.data.description,
      lang: response.data.lang,
      keywords: response.data.keywords,
      events: response.data.events.map((event: Event) => {
        return this.toMap(event, EventModel)
      })
    })
  }

  /**
   * @inheritDoc
   */
  public async fetchGroupedEventsCollection (slug: string = ''): Promise<EventsGroupModel[]> {
    const response = await this.call('EventResources', 'fetchGroupedEvents', { slug })

    return response.data.map((eg: any) => {
      return EventsGroupModel.hydrate<EventsGroupModel>({
        title: eg.title,
        lang: eg.lang,
        city: eg.city,
        image: eg.image,
        url: eg.url,
        eventsCount: eg.events_count
      })
    })
  }

  /**
   * @inheritDoc
   */
  public async fetchPromotedEvents (): Promise<EventModel[]> {
    const response = await this.call('EventResources', 'fetchPromotedEvents')
    return this.toMapCollection(response, EventModel, false) as EventModel[]
  }

  /**
   * @inheritDoc
   */
  public async search (phrase: string, pageSize = 4): Promise<Pageable<EventModel>> {
    const response = await this.call('EventResources', 'fetchEvents', { search: phrase, 'page_size': pageSize, 'not_ended': true } )
    return this.toMapCollection(response, EventModel) as Pageable<EventModel>
  }
}
