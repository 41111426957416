
import { Component, Prop, Vue } from 'vue-property-decorator'

import { LinkProps } from './Link.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Link' })
export class Link extends Vue {
  /**
   * @see LinkProps.label
   */
  @Prop({ type: String, required: true })
  public readonly label!: LinkProps['label']

  /**
   * @see LinkProps.label
   */
  @Prop({ type: [Object, String], required: true })
  public readonly toLocation!: LinkProps['toLocation']

  /**
   * @see LinkProps.disabled
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: LinkProps['disabled']

  public get isExternal (): boolean {
    if (typeof this.toLocation === 'string') {
      return (this.toLocation.startsWith('http://') || this.toLocation.startsWith('https://'))
    }

    return false
  }
}
export default Link
