
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VPopover } from 'v-tooltip'

import { DROPDOWN_POPOVER_DEFAULT_CONFIG } from './Dropdown.config'

@Component({ name: 'Dropdown', components: { VPopover }  })
export class Dropdown extends Vue {
  @Prop({ type: String, required: true })
  public readonly label!: string

  @Prop({ type: Array, required: true })
  public readonly items!: any[]

  public readonly config = DROPDOWN_POPOVER_DEFAULT_CONFIG

  public isOpen = false

  public toggle (): void {
    this.isOpen = !this.isOpen
  }
}

export default Dropdown
