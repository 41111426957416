import { AsyncComponent, defineAsyncComponent } from 'vue'

import { Icon } from './icons'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const iconsRegistry: Record<Icon, AsyncComponent> = {
  [Icon.Arts]: defineAsyncComponent(() => import('./Arts.vue' as string)),
  [Icon.Album]: defineAsyncComponent(() => import('./Album.vue' as string)),
  [Icon.ArrowOutward]: defineAsyncComponent(() => import('./ArrowOutward.vue' as string)),
  [Icon.ArrowBack]: defineAsyncComponent(() => import('./ArrowBack.vue' as string)),
  [Icon.Calendar]: defineAsyncComponent(() => import('./Calendar.vue' as string)),
  [Icon.Concerts]: defineAsyncComponent(() => import('./Concerts.vue' as string)),
  [Icon.Comedy]: defineAsyncComponent(() => import('./Comedy.vue' as string)),
  [Icon.Conference]: defineAsyncComponent(() => import('./Conference.vue' as string)),
  [Icon.Card]: defineAsyncComponent(() => import('./Card.vue' as string)),
  [Icon.Close]: defineAsyncComponent(() => import('./Close.vue' as string)),
  [Icon.ChevronLeft]: defineAsyncComponent(() => import('./ChevronLeft.vue' as string)),
  [Icon.ChevronRight]: defineAsyncComponent(() => import('./ChevronRight.vue' as string)),
  [Icon.ChevronDownSharp]: defineAsyncComponent(() => import('./ChevronDownSharp.vue' as string)),
  [Icon.CloseRaw]: defineAsyncComponent(() => import('./CloseRaw.vue' as string)),
  [Icon.Eye]: defineAsyncComponent(() => import('./Eye.vue' as string)),
  [Icon.Exhibitions]: defineAsyncComponent(() => import('./Exhibitions.vue' as string)),
  [Icon.Filters]: defineAsyncComponent(() => import('./Filters.vue' as string)),
  [Icon.Food]: defineAsyncComponent(() => import('./Food.vue' as string)),
  [Icon.Festivals]: defineAsyncComponent(() => import('./Festivals.vue' as string)),
  [Icon.Hamburger]: defineAsyncComponent(() => import('./Hamburger.vue' as string)),
  [Icon.Heart]: defineAsyncComponent(() => import('./Heart.vue' as string)),
  [Icon.HeartFilled]: defineAsyncComponent(() => import('./HeartFilled.vue' as string)),
  [Icon.Location]: defineAsyncComponent(() => import('./Location.vue' as string)),
  [Icon.Movies]: defineAsyncComponent(() => import('./Movies.vue' as string)),
  [Icon.Map]: defineAsyncComponent(() => import('./Map.vue' as string)),
  [Icon.Music]: defineAsyncComponent(() => import('./Music.vue' as string)),
  [Icon.Parties]: defineAsyncComponent(() => import('./Parties.vue' as string)),
  [Icon.Share]: defineAsyncComponent(() => import('./Share.vue' as string)),
  [Icon.Search]: defineAsyncComponent(() => import('./Search.vue' as string)),
  [Icon.Sports]: defineAsyncComponent(() => import('./Sports.vue' as string)),
  [Icon.Sightseeing]: defineAsyncComponent(() => import('./Sightseeing.vue' as string)),
  [Icon.Person]: defineAsyncComponent(() => import('./Person.vue' as string)),
  [Icon.Theaters]: defineAsyncComponent(() => import('./Theaters.vue' as string)),
  [Icon.Tick]: defineAsyncComponent(() => import('./Tick.vue' as string)),
  [Icon.Ticket]: defineAsyncComponent(() => import('./Ticket.vue' as string)),
  [Icon.Workshops]: defineAsyncComponent(() => import('./Workshops.vue' as string)),

}
