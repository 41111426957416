
import { Component, Vue } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'

import { IRootService, RootServiceType } from '@/modules/root/services/root'

@Component<FilterAware>({
  name: 'FilterAware',
})
export class FilterAware extends Vue {
  protected readonly rootService: IRootService = this.$container.get<IRootService>(RootServiceType)

  public get appliedFiltersBadge () {
    if (Object.keys(this.appliedFilters).length === 0) {
      return {}
    }

    return Object.entries(this.appliedFilters).reduce((acc, [key, value]) => {
      let label: string | undefined = ''

      switch (key) {
        case 'period':
          label = this.periods.find((period) => period.key == value)?.value
          break
        case 'categories':
          const _categories: string[] = value.split(',')

          for (const category of _categories) {
            const foundCategory = this.categories.find((_category) => _category.key === category)
            if (foundCategory) {
              label += `${foundCategory.label.toString()}${_categories.indexOf(category) !== _categories.length - 1  ? ', ' : ''}`
            }
          }
          break
        case 'cities':
          const _cities: string[] = value.split(',')

          for (const city of _cities) {
            const foundCity = this.cities.find((_city) => _city.city === city)
            if (foundCity) {
              label += `${foundCity.city.toString()}${_cities.indexOf(city) !== _cities.length - 1  ? ', ' : ''}`
            }
          }
          break
      }

      return {
        ...acc,
        [key]: {
          key,
          label
        }
      }
    }, {})
  }

  public get appliedFilters (): AnyObject {
    return this.queryParams ?? {}
  }

  public get categories (): AnyObject[] {
    return this.filters.categories ?? []
  }

  public get filters (): AnyObject {
    return this.rootService.getProperty('filters')
  }

  public get periods (): AnyObject[] {
    return Object.entries(this.filters?.period).map(([key, value]) => {
      return {
        key,
        value
      }
    })
  }

  public get cities (): AnyObject[] {
    return this.filters.cities ?? []
  }

  public get queryParams(): AnyObject {
    return this.$route.query ?? {}
  }

  public set queryParams (params: AnyObject) {
    this.$router.push({
      name: this.$route.name!,
      query: params
    })
  }

  public flushFilters(): void {
    this.queryParams = {}
  }

  public onRemoveFilter(filterKey: string): void {
    let cpQueryParams = { ...this.queryParams }

    if (filterKey in cpQueryParams) {
      delete cpQueryParams[filterKey]
    }

    this.queryParams = cpQueryParams
  }
}
export default FilterAware
