import { injectable } from 'inversify'
import axios, { AxiosInstance } from 'axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import { AnyObject, IResource } from '../../general'

import { findResourceAction, findResourceEntity, replaceVariables } from './http-connnector.helpers'
import { IHttpConnector } from './http-connector.contracts'
import { IMiddleware } from '@/core'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@injectable()
export class HttpConnector implements IHttpConnector {
  protected readonly axios: AxiosInstance

  protected resources: IResource[] = []
  protected middlewares: IMiddleware[] = []

  constructor(config: AxiosRequestConfig) {
    this.axios = axios.create(config)
  }

  /**§
   * @inheritDoc
   */
  public async call (name: string, action: string, params?: AnyObject, body?: AnyObject): Promise<AxiosResponse> {
    const foundResourceEntity = findResourceEntity(name, this.resources)
    const {
      method,
      params: queryParams,
      url: rawUrl
    }= findResourceAction(action, foundResourceEntity)

    let headers = {}

    for (const middleware of this.middlewares) {
      const beforeRequestParams = middleware.beforeRequest(foundResourceEntity)

      if (typeof beforeRequestParams !== 'undefined') {
        headers = { ...headers, ...beforeRequestParams.headers }
      }
    }

    const { url, filteredParams } = replaceVariables(rawUrl, params || {}, queryParams || []) // Replace variables

    const response = await this.axios.request({
      method: method.toLowerCase(),
      url: `${foundResourceEntity.prefix}/${url}`,
      data: body,
      params: filteredParams,
      headers,
    })

    for (const middleware of this.middlewares) {
      middleware.afterRequest(response)
    }

    return response
  }

  /**
   * @inheritDoc
   */
  public registerMiddleware (middleware: IMiddleware | IMiddleware[]): void {
    if (Array.isArray(middleware)) {
      this.middlewares = middleware
      return
    }
    this.middlewares.push(middleware)
  }

  /**
   * @inheritDoc
   */
  public registerResource(resource: IResource | IResource[]): void {
    if (Array.isArray(resource)) {
      this.resources = resource
      return
    }
    this.resources.push(resource)
  }

  /**
   * @inheritDoc
   */
  public unregisterResource(resource: IResource): void {
    this.resources.splice(this.resources.indexOf(resource))
  }
}
