import { injectable } from 'inversify'

import { IWishlistService, WishlistBucket, WishlistBucketKey, WishlistItem } from './contracts'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@injectable()
export class WishlistService implements IWishlistService {
  protected wishlist: WishlistBucket | null = null

  /**
   * @inheritDoc
   */
  public add<I> (item: WishlistItem<I>) {
    let bucket = this.retrieve()

    // Prevent adding the same ID elements.
    if (bucket?.items.findIndex(_item => _item.id === item.id) !== -1) {
      return
    }

    const items = [...bucket?.items ?? [], item]

    bucket = { items, count: items.length }

    this.save(bucket)
  }

  /**
   * @inheritDoc
   */
  public remove<I> (item: WishlistItem<I>) {
    //
  }

  /**
   * @inheritDoc
   */
  public removeById (id: number): void {
    let bucket = this.retrieve()

    const items = [...bucket?.items ?? []].filter((item) => {
      return item.id !== id
    })

    bucket = { items, count: items.length }

    this.save(bucket)
  }

  public static createBucket (): WishlistBucket {
    const newBucket: WishlistBucket = {
      items: [],
      count: 0
    }

    localStorage.setItem(WishlistBucketKey, JSON.stringify(newBucket))

    return newBucket
  }

  // Note!: Fire this function only when document exists!
  public initBucket (): void {
    let wishlistBucket: WishlistBucket | undefined
    let wishlistRawBucketOrNull = localStorage.getItem(WishlistBucketKey)

    if (!wishlistRawBucketOrNull) {
      wishlistBucket = WishlistService.createBucket()
    } else {
      wishlistBucket = JSON.parse(wishlistRawBucketOrNull)
    }

    this.wishlist = {
      items: wishlistBucket?.items ?? [],
      count: wishlistBucket?.count ?? 0
    }
  }

  public hasItemById (id: number): boolean {
    return typeof this.wishlist?.items.find((item) => item.id === id) !== 'undefined'
  }

  public retrieve (bucketKey: string = WishlistBucketKey): WishlistBucket | null {
    if (typeof window === 'undefined') {
      return null
    }

    const candidate = localStorage.getItem(bucketKey)

    if (!candidate) {
      throw new Error('No wishlist found for given bucket key!')
    }

    return JSON.parse(candidate) as WishlistBucket
  }

  public retrieveItems<I> (): WishlistItem<I>[] {
    return this.wishlist?.items ?? []
  }

  protected save (bucket: WishlistBucket): void {
    if (typeof window !== 'undefined') {
      localStorage.setItem(WishlistBucketKey, JSON.stringify(bucket))
    }
  }
}