import { interfaces } from 'inversify'

import { IModule } from '@/core/general'
import { IModal, ModalServiceType } from '@/core/services/modal'
import { DrawerServiceType, IDrawer } from '@/core/services/drawer'

import { HttpConnectorType, IHttpConnector } from '@/core/connectors/http'

import { HeadersMiddleware, HeadersMiddlewareType } from '@/shared/middlewares/headers.middleware'
import { IAmplitude, AmplitudeType, Amplitude } from '@/shared/services/amplitude'

import { rootModuleDrawersRegistry, rootModuleModalsRegistry, RootResources, routes, store } from './config'
import { IRootService, RootServiceType, RootService } from './services/root'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const RootModule: IModule = {
  name: 'root',
  providers: (((bind, appContext) => {
    bind<IRootService>(RootServiceType).toDynamicValue((context: interfaces.Context) => {
      return new RootService(context.container.get<IHttpConnector>(HttpConnectorType))
    }).inSingletonScope()

    bind<IAmplitude>(AmplitudeType).to(Amplitude).inSingletonScope()

    bind<HeadersMiddleware>(HeadersMiddlewareType).toDynamicValue((context: interfaces.Context) => {
      return new HeadersMiddleware(appContext)
    })
  })),
  onload: (container): void => {
    const connector = container.get<IHttpConnector>(HttpConnectorType)
    const modalService = container.get<IModal>(ModalServiceType)
    const drawerService = container.get<IDrawer>(DrawerServiceType)

    connector.registerResource(RootResources)
    connector.registerMiddleware(container.get(HeadersMiddlewareType))

    drawerService.registerAll(rootModuleDrawersRegistry)
    modalService.registerAll(rootModuleModalsRegistry)
  },
  routes,
  store
}
