import { Component } from 'vue'

// Atoms
import { Badge } from './atoms/Badge'
import { Button } from './atoms/Button'
import { Breadcrumbs } from './atoms/Breadcrumbs'
import { Card } from './atoms/Card'
import { Container } from './atoms/Container'
import { Check } from './atoms/Check'
import { DropdownItem } from './atoms/DropdownItem'
import { Error } from './atoms/Error'
import { Heading } from './atoms/Heading'
import { Icon } from './atoms/Icon'
import { Image } from './atoms/Image'
import { Input } from './atoms/Input'
import { Link } from './atoms/Link'
import { IconButton } from './atoms/IconButton'
import { Radio } from './atoms/Radio'
import { Textarea } from './atoms/Textarea'

// Molecules
import { Carousel } from './molecules/Carousel'
import { CarouselTile } from '@/dsl/molecules/CarouselTile'
import { DrawerInner } from './molecules/DrawerInner'
import { Dropdown } from './molecules/Dropdown'
import { ModalInner } from './molecules/ModalInner'

export const dslComponentsRegistry: Record<string, Component> = {
  // Atoms
  Badge,
  Button,
  Breadcrumbs,
  Card,
  Container,
  Check,
  DropdownItem,
  Error,
  Heading,
  Input,
  Image,
  Icon,
  IconButton,
  Link,
  Radio,
  Textarea,

  // Molecules
  ModalInner,
  Carousel,
  CarouselTile,
  DrawerInner,
  Dropdown
}