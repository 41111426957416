import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface AsyncDataContext {
  app: Vue
  route: Route
  router: VueRouter
  isClient: boolean
  isServer: boolean
  [key: string]: any
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum LocaleKey {
  EN = 'EN',
  PL = 'PL',
  RU = 'RU'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type Locale = { [key: string]: string }

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type KeyLabel = { key: string; label: string }
