import { ModalRegister } from '@/core/services/modal'

import { ContactUsDrawer } from '../components/organisms/ContactUsDrawer'
import { CitySelectorDrawer } from '../components/organisms/CitySelectorDrawer'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export enum RootModuleDrawersCollection {
  ContactUs = 'contact_us',
  CityChoices = 'city_choices'
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const rootModuleDrawersRegistry: ModalRegister = {
  [RootModuleDrawersCollection.ContactUs]: ContactUsDrawer,
  [RootModuleDrawersCollection.CityChoices]: CitySelectorDrawer
}