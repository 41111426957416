/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum Icon {
  Arts = 'arts',
  Album = 'album',
  ArrowOutward = 'arrow-outward',
  ArrowBack = 'arrow-back',
  Concerts = 'concerts',
  Conference = 'conference',
  Comedy = 'comedy',
  Calendar = 'calendar',
  Card = 'card',
  Close = 'close',
  CloseRaw = 'close-raw',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronDownSharp = 'chevron-down-sharp',
  // Dance = 'dance',
  Exhibitions = 'exhibitions',
  Eye = 'eye',
  Festivals = 'festivals',
  Food = 'food',
  Filters = 'filters',
  Hamburger = 'hamburger',
  Heart = 'heart',
  HeartFilled = 'heart-filled',
  Location = 'location',
  Movies = 'movies',
  Map = 'map',
  Music = 'music',
  Parties = 'parties',
  Share = 'share',
  Search = 'search',
  Sports = 'sports',
  Sightseeing = 'sightseeing',
  Person = 'person',
  Theaters = 'theaters',
  Tick = 'tick',
  Ticket = 'ticket',
  Workshops = 'workshops',
}
