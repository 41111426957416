import 'reflect-metadata'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import VueLazyload from 'vue-lazyload'

import { Core } from '@/core/plugin'

import { createSSRApp } from '@/config/app'
import { initSentry } from '@/config/sentry'
import { messages } from '@/config/i18n'

import { DSLPlugin } from '@/dsl'

import { IRootService, RootServiceType } from '@/modules/root/services/root'
import { Directives } from '@/shared/directives'

import App from './App.vue'
import './scss/main.scss'

Vue.config.productionTip = false

Vue.use(VueLazyload)
Vue.use(DSLPlugin)
Vue.use(Core)
Vue.use(Directives)
Vue.use(VueMeta)
Vue.use(VueI18n);
Vue.use(VueRouter)
Vue.use(Vuex)

initSentry()

export default (context: any) => {
  return createSSRApp(context).then(({ router, store, container }) => {

    const i18n = new VueI18n({
      locale: 'en',
      fallbackLocale: 'en',
      messages,
    })

    // Set the default locale.
    i18n.locale = container.get<IRootService>(RootServiceType).getLocale().toLowerCase()

    return new Vue({
      i18n,
      router,
      container,
      store,
      render: h => h(App)
    })
  })
}