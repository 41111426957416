import * as amplitude from '@amplitude/analytics-browser'
import { injectable } from 'inversify'

import { AnyObject } from '@/core/general'

import { AppAmplitudeEvent, IAmplitude } from './contracts'

/**
 * @author Javlon Khalimjanov <khalimjanov2000@gmail.com>
 */
@injectable()
export class Amplitude implements IAmplitude {
  constructor () {
    if (process.env.VUE_APP_ENV === 'production') {
      amplitude.init(process.env.VUE_APP_AMPLITUDE_SECRET_KEY)
    }
  }

  /**
   * @inheritDoc
   */
  public emit (eventName: AppAmplitudeEvent, payload: AnyObject): void {
    amplitude.track(eventName, payload)
  }
}