
import { Component, Prop, Vue } from 'vue-property-decorator'

import { resolveTheme } from '../../helpers'

import {
  ButtonProps,
  buttonThemeRegistry,
  buttonVariantRegistry,
  Theme,
  Variant
} from './Button.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Button' })
export class Button extends Vue {
  /**
   * @see ButtonProps.disabled
   */
  @Prop({ type: Boolean, required: false, default: false })
  protected readonly disabled!: ButtonProps['disabled']

  /**
   * @see ButtonProps.type
   */
  @Prop({ type: Boolean, required: false, default: false })
  protected readonly readonly!: ButtonProps['readonly']

  /**
   * @see ButtonProps.isFluid
   */
  @Prop({ type: Boolean, required: false, default: false })
  protected readonly isFluid!: ButtonProps['isFluid']

  /**
   * @see ButtonProps.type
   */
  @Prop({ type: String, required: false, default: 'button' })
  protected readonly type!: ButtonProps['type']

  /**
   * @see ButtonProps.type
   */
  @Prop({ type: String, required: false, default: Theme.Primary })
  protected readonly theme!: ButtonProps['theme']

  /**
   * @see ButtonProps.type
   */
  @Prop({ type: String, required: false, default: Variant.Default })
  protected readonly variant!: ButtonProps['variant']

  public get resolvedTheme (): string {
    return resolveTheme(this.theme, buttonThemeRegistry, Theme.Primary)
  }

  public get resolvedVariant (): string {
    return resolveTheme(this.variant, buttonVariantRegistry, Variant.Default)
  }

  public click (): void {
    this.$emit('click')
  }
}
export default Button
