export enum ModalSize {
  Auto = 'auto',
  Large = 'large',
  Medium = 'medium'
}

export const modalSizeRegistry: Record<ModalSize, string> = {
  [ModalSize.Auto]: '',
  [ModalSize.Large]: '--large',
  [ModalSize.Medium]: '--medium'
}
