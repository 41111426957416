
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'DropdownItem'})
export class DropdownItem extends Vue {
  @Prop({ type: String, required: true })
  public readonly label!: string

  @Prop({ type: Function, required: false })
  public readonly action!: CallableFunction

  @Prop({ type: Function, required: true })
  public readonly closeCallback!: CallableFunction

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isDisabled!: boolean

  public onDropdownItemClick (): void {
    this.action(this.closeCallback)
  }
}

export default DropdownItem
