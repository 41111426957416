import { Container } from 'inversify'

import { IModule } from '@/core/general'

import { routes, store } from './config'
import { IWishlistService, WishlistService, WishlistServiceType } from './services/wishlist'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const FavoritesModule: IModule = {
  name: 'favourites',
  providers: (((bind) => {
    bind<IWishlistService>(WishlistServiceType).to(WishlistService).inSingletonScope()
  })),
  onload (container: Container): void {
    //
  },
  routes,
  store
}