
import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component<PWAPrompt>({
  name: 'PWAPrompt',
  beforeMount (): void {
    this.initPWAPrompt()
  }
})
export class PWAPrompt extends Vue {
  public isVisible: boolean = false
  public installPromptEventObject: any = null

  public dismissPrompt() {
    localStorage.setItem('prompted', 'yes')
    this.isVisible = false
  }

  public installPWA (): void {
    this.installPromptEventObject.prompt()
    this.installPromptEventObject.userChoice.then((choice: any) => {
      if (choice.outcome === 'accepted') {
        // Do something additional if the user chose to install
      } else {
        // Do something additional if the user declined
      }
      this.dismissPrompt() // Hide the prompt once the user's clicked
    })
  }

  protected initPWAPrompt (): void {
    if (typeof window !== 'undefined') {

      const prompted = localStorage.getItem('prompted')

      if (prompted) {
        return
      }

      window.addEventListener('beforeinstallprompt', (e) => {
        console.log(e)
        e.preventDefault()
        this.installPromptEventObject = e
        this.isVisible = true
      })
    }
  }
}
export default PWAPrompt
