
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { Icon as IconName } from '@/dsl/icons/icons'


/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Icon' })
export class Icon extends Vue {
  /**
   * Determines icons registry provided bu the plugin.
   */
  @Inject({ from: 'icons' })
  protected readonly icons!: Record<IconName, VueConstructor>

  @Prop({ type: String, required: false, default: 'currentColor' })
  public readonly color!: string

  @Prop({ type: Number, required: false, default: 16 })
  public readonly width!: string

  @Prop({ type: Number, required: false, default: 16 })
  public readonly height!: string

  @Prop({ type: String, required: true })
  protected readonly name!: IconName

  public get component(): VueConstructor {
    return this.icons[this.name]
  }
}
export default Icon
