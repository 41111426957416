import { AxiosResponse } from 'axios'

import { AnyObject, IResource } from '../../general'
import { IMiddleware } from '@/core'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface IHttpConnector {
  call (name: string, action: string, params?: AnyObject, body?: AnyObject): Promise<AxiosResponse>
  registerResource (resource: IResource | IResource[]): void
  registerMiddleware (middleware: IMiddleware | IMiddleware[]): void
  unregisterResource (resource: IResource): void
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const HttpConnectorType = Symbol.for('IHttpConnector')