import { AxiosResponse } from 'axios'
import { injectable } from 'inversify'

import { AdditionalRequestParams, IMiddleware, parseCookie } from '@/core'
import { AnyObject, IResource } from '@/core/general'


/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@injectable()
export class HeadersMiddleware implements IMiddleware {
  protected context: any

  constructor (appContext: any) {
    this.context = appContext
  }

  /**
   * @inheritDoc
   */
  public beforeRequest (resource: IResource): AdditionalRequestParams {
    const headers: AnyObject = {}

    if (this.context.isServer) {
      headers['x-forwarded-for'] = this.context?.req.headers['x-forwarded-for'] ?? '89.64.71.241'
      headers['User-Agent'] = this.context?.req.headers['user-agent'] ?? 'axios'

      const appCookie: string | undefined = this.context?.req ? this.context?.req.headers.cookie : undefined
      if (appCookie && parseCookie(appCookie)?.lang) {
        headers['Language-Code'] = parseCookie(appCookie)?.lang
      } else {
        headers['Accept-Language'] = this.context.req.headers['accept-language'] ?? ''
      }

      if (appCookie && parseCookie(appCookie)?.geo_location) {
        headers['Geo-Location'] = parseCookie(appCookie)?.geo_location
      }

      if (appCookie && parseCookie(appCookie)?.user_selected_location) {
        headers['User-Selected-Location'] = parseCookie(appCookie, false)?.user_selected_location
      }
    } else {
      if (parseCookie(document.cookie) && parseCookie(document.cookie)?.lang) {
        headers['Language-Code'] = parseCookie(document.cookie)?.lang
      } else {
        headers['Accept-Language'] = window.navigator.language
      }

      if (parseCookie(document.cookie) && parseCookie(document.cookie)?.geo_location) {
        headers['Geo-Location'] = parseCookie(document.cookie)?.geo_location
      }

      if (parseCookie(document.cookie) && parseCookie(document.cookie)?.user_selected_location) {
        headers['User-Selected-Location'] = parseCookie(document.cookie,false)?.user_selected_location
      }
    }

    return {
      headers
    }
  }

  /**
   * @inheritDoc
   */
  public afterRequest (response: AxiosResponse): void {
    //
  }
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const HeadersMiddlewareType = Symbol.for('HeadersMiddleware')
