/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const debounceTimeout = 500

export const SEARCHBAR_POPOVER_CONFIG = {
  /**
   * Automatically close the tooltip on target click.
   */
  defaultHideOnTargetClick: true,

  /**
   * Determines offset from top.
   */
  offset: '0',

  /**
   * Automatically close the tooltip on target click.
   */
  placement: 'bottom',

  /**
   * Class for the inner wrapper of the popover
   *
   * NOTE!: `popover-inner` is not MUST HAVE, it can be overrridden
   */
  popoverInnerClass: 'popover-inner',

  container: '.SearchBar__container'
}