import { RouteConfig } from 'vue-router'
import { defineAsyncComponent } from 'vue'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const enum RouteName {
  Event = 'event',
  Events = 'events',
  SearchResults = 'search-results',
  GroupedEvents = 'grouped-events',
  Map = 'map',
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const routes: Array<RouteConfig> = [
  {
    path: '/events/:slug',
    component: defineAsyncComponent(() => import('../../../shared/templates/WithFooter.vue')),
    children: [
      {
        path: '',
        name: RouteName.Event,
        component: defineAsyncComponent(() => import('../views/Event/Event.vue'))
      }
    ]
  },
  {
    path: '/events',
    component: defineAsyncComponent(() => import('../../../shared/templates/WithFooter.vue')),
    children: [
      {
        path: '',
        name: RouteName.Events,
        component: defineAsyncComponent(() => import('../views/Events.vue')),
      },
      {
        path: '/search-results',
        name: RouteName.SearchResults,
        component: defineAsyncComponent(() => import('../views/SearchResults.vue')),
      }
    ]
  },
  {
    path: '/map',
    name: RouteName.Map,
    component: defineAsyncComponent(() => import('../views/Map.vue'))
  },
  {
    path: '/:group',
    component: defineAsyncComponent(() => import('../../../shared/templates/WithFooter.vue')),
    children: [
      {
        path: '',
        name: RouteName.GroupedEvents,
        component: defineAsyncComponent(() => import('../views/EventsGroup.vue'))
      }
    ]
  },
]
