
import { Component, Prop, Vue } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'

import { IRootService, RootServiceType } from '../../../services/root'

@Component<CitySelectorModal>({
  name: 'CitySelectorModal',
})
export class CitySelectorModal extends Vue {
  @Prop({ type: Function, required: true })
  public close!: CallableFunction

  protected readonly rootService =
    this.$container.get<IRootService>(RootServiceType)

  public get cityList (): AnyObject[] {
    return this.rootService.getProperty('city_choices') ?? []
  }

  public city: string = this.cityList.find((city) => city.city ===
    this.rootService.getProperty('city'))?.city

  public countryCode: string = this.cityList.find((city) => city.city ===
    this.rootService.getProperty('city'))?.country_code

  public save (): void {
    document.cookie =
      `user_selected_location=${encodeURIComponent(this.city)},${this.countryCode};path=/;expires=Tue, 19 Jan 2038 04:14:07 GMT`
    this.close()
    document.location.reload()
  }
}
export default CitySelectorModal
