
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'CookieConsent' })
export class CookieConsent extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hidden!: boolean

  public close(): void {
    localStorage.setItem('consent', 'true')
    this.$emit('close')
  }
}

export default CookieConsent
