/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum Theme {
  Primary = 'primary',
  Secondary = 'secondary',
  Accent = 'accent',
  Transparent = 'transparent'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface CanHaveTheme<T> {
  theme: T
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum ThemeStyle {
  Filled = 'filled',
  Outline = 'outline'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface CanHaveThemeStyle {
  themeStyle?: ThemeStyle
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultThemeRegistry: Record<Theme, string> = {
  [Theme.Accent]: '--accent',
  [Theme.Primary]: '--primary',
  [Theme.Secondary]: '--secondary',
  [Theme.Transparent]: '--transparent'
}