import { VueConstructor } from 'vue'
import { Observable } from 'rxjs'

import { AnyObject } from '../../general'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type ModalName = string

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ModalState<P extends AnyObject = AnyObject> {
  open?: boolean,
  modal?: VueConstructor
  payload?: P,
  options?: ModalOptions
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ModalOptions {
  onClose (): CallableFunction
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type ModalRegister = Record<ModalName, VueConstructor>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface IModal {
  registerAll (register: ModalRegister): void
  close (): void
  show<P extends AnyObject> (name: ModalName, payload: P, options?: ModalOptions): void
  state$: Observable<ModalState>
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const ModalServiceType = Symbol.for('IModal')