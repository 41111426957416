import { AnyObject } from '@/core/general'
import { Marker } from '@/shared/services/leaflet/marker'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ILeafletService {
 addLayer (id?: any): void
 getInstance(): LeafletInstance | null
 init(referenceElement: string, position: MarkerPosition): void
 setMarker (marker: Marker): void
 flyToLocation (position: MarkerPosition): void
 removeLayer (layerId: string): void
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface LeafletInstance {
  flyTo: (position: number[], zoom?: number) => void
  addLayer: (layer: any) => void
  removeLayer: (layer: any) => void
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const LeafletServiceType = Symbol.for('ILeafletService')

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface MarkerPosition {
  latitude: number
  longitude: number
}