import { IModule } from '@/core/general'

import { HttpConnectorType, IHttpConnector } from '@/core/connectors/http'
import { ModalServiceType, IModal } from '@/core/services/modal'
import { DrawerServiceType, IDrawer } from '@/core/services/drawer'

import { loaderMiddleware } from '@/shared/middlewares/loader.middleware'

import { LeafletService, ILeafletService, LeafletServiceType } from '@/shared/services/leaflet'

import { routes, EventResources, eventsModuleModalsRegistry, eventsModuleDrawersRegistry, store } from './config'
import { EventsRepositoryType } from './contracts'
import { EventsRepository } from './repositories'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const EventsModule: IModule = {
  name: 'events',
  providers: (bind) => {
    bind<EventsRepository>(EventsRepositoryType).toDynamicValue(context => {
      return new EventsRepository(context.container.get<IHttpConnector>(HttpConnectorType))
    })

    bind<ILeafletService>(LeafletServiceType).to(LeafletService).inSingletonScope()
  },
  onload (container): void {
    const connector = container.get<IHttpConnector>(HttpConnectorType)
    const modalService = container.get<IModal>(ModalServiceType)
    const drawerService = container.get<IDrawer>(DrawerServiceType)


    connector.registerResource(EventResources)
    connector.registerMiddleware(loaderMiddleware)

    modalService.registerAll(eventsModuleModalsRegistry)
    drawerService.registerAll(eventsModuleDrawersRegistry)
  },
  routes,
  store
}
