
import { Component, Provide, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { captureMessage } from '@sentry/vue'

import { Navbar } from '@/shared/components/organisms/Navbar'
import { Modal } from '@/shared/components/organisms/Modal'
import { Drawer } from '@/shared/components/organisms/Drawer'
import { PWAPrompt } from '@/shared/components/organisms/PWAPrompt'
import { CookieConsent } from '@/shared/components/organisms/CookieConsent'

import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { IWishlistService, WishlistServiceType } from '@/modules/favourites/services/wishlist'
import { IRootService, RootServiceType } from '@/modules/root/services/root'

@Component<App>({
  name: 'App',
  components: { Modal, Drawer, Navbar, PWAPrompt, CookieConsent },
  metaInfo (): MetaInfo {
    return {
      title: this.rootService.getProperty('meta')?.title ?? '',
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          href: `${process.env.BASE_URL}icons/site_logo.png`
        },
        {
          rel: 'manifest',
          href: `${process.env.BASE_URL}manifest.json`
        }
      ],
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          vmid: 'description',
          name: 'og:description',
          content: this.rootService.getProperty('meta')?.description ?? ''
        },
        {
          vmid: 'desc',
          name: 'description',
          content: this.rootService.getProperty('meta')?.description ?? ''
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.rootService.getProperty('meta')?.keywords ?? ''
        },
        {
          name: 'robots',
          content: 'index, follow'
        }
      ]
    }
  },
  mounted (): void {
    if (typeof window !== 'undefined') {
      this.wishlistService.initBucket()
      this.$store.commit('favourites/setWishlist', this.wishlistService.retrieve())

      this.promptForLocation()
      this.setFirstVisit()

      if (!this.checkCookieConsent()) {
        this.cookieConsentVisible = true
      }
    }
  },
  errorCaptured (error: Error): void {
    captureMessage(error.message, 'error')
  }
})
export class App extends Vue {
  protected readonly wishlistService =
    this.$container.get<IWishlistService>(WishlistServiceType)

  protected readonly rootService =
    this.$container.get<IRootService>(RootServiceType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  @Provide('isMobile')
  protected determinesMobile (): boolean {
    if (typeof window !== 'undefined') {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    }

    return false
  }

  public cookieConsentVisible = false

  protected promptForLocation (): void {
    navigator.geolocation.getCurrentPosition((position: any) => {
      const { latitude, longitude, accuracy, speed, altitude, altitudeAccuracy } = position.coords
      this.rootService.setProperty('location', { latitude, longitude })

      const coords = {
        latitude,
        longitude,
        accuracy,
        speed,
        altitude,
        altitudeAccuracy
      }

      document.cookie = `geo_location=${JSON.stringify(coords)};path=/;expires=Tue, 19 Jan 2038 04:14:07 GMT`
    }, () => {
      //
    })
  }

  protected setFirstVisit (): void {
    if (!localStorage.getItem('visited')) {
      localStorage.setItem('visited', '1')
      this.amplitude.emit(AppAmplitudeEvent.ON_LAUNCH_FIRST_TIME)
    }
  }

  protected checkCookieConsent (): boolean {
    return localStorage.getItem('consent') ? Boolean(localStorage.getItem('consent')) : false
  }
}

export default App
