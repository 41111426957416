import { AnyObject } from '../../general'

import { ModalName, ModalState, ModalOptions, ModalRegister, IModal } from '../modal'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */

export type DrawerName = ModalName

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type DrawerState<P extends AnyObject = AnyObject> = ModalState<P>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type DrawerOptions = ModalOptions

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type DrawerRegister = ModalRegister

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type IDrawer = IModal

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const DrawerServiceType = Symbol.for('IDrawer')