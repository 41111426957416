
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Subscription } from 'rxjs'
import { VueConstructor } from 'vue'

import { IDrawer, DrawerState, DrawerServiceType, DrawerOptions } from '@/core/services/drawer'
import { AnyObject } from '@/core/general'

@Component<Drawer>({
  name: 'Drawer',
  mounted (): void {
    this.subscription = this.drawerService.state$.subscribe((state: DrawerState) => {
      this.state = state
    })
  },
  beforeDestroy (): void {
    this.subscription?.unsubscribe()
  }
})
export class Drawer extends Vue {
  protected readonly drawerService = this.$container.get<IDrawer>(DrawerServiceType)

  protected state: DrawerState = {}

  protected subscription: Subscription | null = null

  public get component (): VueConstructor {
    if (this.state.modal) {
      return this.state.modal
    }

    throw new Error('No drawer component found in state!')
  }

  public get withDelayedOpen (): boolean {
    if (!this.isOpen) {
      setTimeout(() => {
        return false
      }, 200)
    }
    return this.isOpen
  }

  public get isOpen (): boolean {
    return this.state.open ?? false
  }

  public get payload (): AnyObject | undefined {
    return this.state.payload
  }

  public get options (): DrawerOptions | undefined {
    return this.state.options
  }

  public close (): void {
    setTimeout(() => {
     this.drawerService.close()
    }, 200)
  }

  @Watch('isOpen')
  protected onIsOpen (value: boolean): void {
    if (value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }
}
export default Drawer
