import { interfaces } from 'inversify'

import { HttpConnectorType, IHttpConnector, HttpConnector } from '../connectors/http'
import { IModal, ModalService, ModalServiceType } from '../services/modal'
import { DrawerServiceType, IDrawer } from '../services/drawer'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const defaultProviders = (bind: interfaces.Bind, context?: any) => {
  bind<IHttpConnector>(HttpConnectorType).toDynamicValue(() => {
    return new HttpConnector({
        baseURL: (context && context.isServer) ? process.env.VUE_APP_BASE_URL : process.env.VUE_APP_BASE_URL_CLIENT,
        headers: {
          'Accept': 'application/json',
          'Api-Key': process.env.VUE_APP_API_KEY ?? '',
        }
      })
  }).inSingletonScope()
  bind<IModal>(ModalServiceType).to(ModalService).inSingletonScope()
  bind<IDrawer>(DrawerServiceType).to(ModalService).inSingletonScope()
}
