import { LocaleKey } from '@/shared'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface IRootService {
  /**
   * Determines functions that fetches initial data to run the application.
   */
  init(browserIp: string): Promise<void>

  /**
   * Retrieves the requested property value from the properties.
   */
  getProperty<T>(name: string): T

  /**
   * Retrieves the requested property value from the properties.
   */
  getLocale(): LocaleKey

  /**
   * Sets the new property value under given name.
   */
  setLocale(key: LocaleKey): void

  /**
   * Sets the new property value under given name.
   */
  setProperty<T>(name: string, value: T): void

  /**
   * Send contact form to the mail.
   */
  sendMail(sender: string, subject: string, content: string): Promise<void>
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const RootServiceType = Symbol.for('IRootService')