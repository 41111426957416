import { IResource } from '@/core/general'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const RootResources: IResource = {
  name: 'RootResources',
  prefix: '',
  resources: {
    init: {
      url: 'frontend_init/',
      method: 'POST'
    },
    sendMail: {
      url: 'send_contact_mail/',
      method: 'POST'
    }
  }
}