import VueRouter, { RouteConfig } from 'vue-router'
import Vuex, { ModuleTree } from 'vuex'
import { Container, ContainerModule } from 'inversify'

import { IModule, defaultProviders } from '../general'
import { BootstrappedApp } from './bootstrapper.contracts'

/**
 * **Boostrap**
 *
 * @description - Main application boostrap class that instantiates all necessary services and provides the instance
 * of the IoC container.
 *
 * @constructor ( appName: string, modules: IModule[], context?: any )
 *
 * @see BootstrappedApp
 * @see IModule
 *
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export class Bootstrap {
  protected appName: string
  protected modules: IModule[]
  protected container: Container | undefined = undefined

  private readonly context: any | undefined = undefined

  constructor(appName: string, modules: IModule[], context?: any) {
    this.appName = appName
    this.modules = modules
    this.context = context
  }

  public async boot (): Promise<BootstrappedApp> {
    this.container = new Container()

    // Loads the container for each module.
    const modules = [this.buildDefaultProviders(), ...this.buildModuleContainers()]

    this.container.load(...modules)
    this.fireModulesOnLoad(this.container)

    const routes: Array<RouteConfig> = this.decorateRoutesWithModuleName()
    const vuexModuleTree = this.retrieveStoreModuleTree()
    return {
      router: new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior: (to, from, savedPosition) => {
          if (savedPosition) {
            return savedPosition
          } else {
            return { x: 0, y: 0, behavior: 'smooth' }
          }
        }
      }),
      container: this.container,
      store: new Vuex.Store({
        modules: { ...vuexModuleTree }
      })
    }
  }

  private decorateRoutesWithModuleName (): Array<RouteConfig> {
    const toReturn: Array<RouteConfig> = []

    for (const module of this.modules) {
      module.routes.map((route) => {
        if (route.children?.length) {
          toReturn.push({
            ...route,
            children: route.children?.map(child => ({ ...child, name: `${module.name}.${child.name}` }))
          })
        } else {
          toReturn.push({
            ...route,
            name: `${module.name}.${route.name}`,
          })
        }
      })
    }

    return toReturn
  }

  public fireModulesOnLoad(container: Container): void {
    for (const module of this.modules) {
      try {
        module.onload(container);
      } catch (e) {
        console.error(`Error loading module ${module.name}: ${e}`);
      }
    }
  }

  public buildDefaultProviders (): ContainerModule {
    return new ContainerModule(
      (bind) => {
        defaultProviders(bind, this.context)
      }
    )
  }

  private buildModuleContainers (): ContainerModule[] {
    const containerModules = []

    for (const module of this.modules) {
      const containerModule = new ContainerModule((bind) => {
        if (module.providers) {
          module.providers(bind, this.context)
        }
      })

      containerModules.push(containerModule)
    }

    return containerModules
  }

  public getContainer (): Container | undefined {
    return this.container
  }

  private retrieveStoreModuleTree (): ModuleTree<any> {
    return this.modules.reduce((acc, next) => {
      return {
        ...acc,
        [next.name]: next.store
      }
    }, {}) as ModuleTree<any>
  }
}