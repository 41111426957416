import Vue, { VueConstructor } from 'vue'

function initContainer (Vue: Vue): void {
  if (Vue.$options.container) {
    Vue.$container = Vue.$options.container
  } else if (Vue.$options.parent && Vue.$options.parent.$container) {
    Vue.$container = Vue.$options.parent.$container
  }
}

function install (_Vue: VueConstructor): void {
  _Vue.mixin({
    beforeCreate (this: Vue): void {
      initContainer(this)
    }
  })
}

export { install as Core }