
import { Component, Vue, Watch } from 'vue-property-decorator'
import { VPopover } from 'v-tooltip'
import { debounce } from 'lodash'

import { Pageable } from '@/core/general'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { EventModel } from '../../../models'
import { EventsRepositoryType, IEventsRepository } from '../../../contracts'

import { debounceTimeout, SEARCHBAR_POPOVER_CONFIG } from './SearchBar.config'

@Component({ name: 'SearchBar', components: { VPopover } })
export class SearchBar extends Vue {
  private readonly eventsRepository =
    this.$container.get<IEventsRepository>(EventsRepositoryType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  //
  public events: Pageable<EventModel> | null = null
  public errors: Record<string, string> | null = {}
  public isLoading: boolean = false
  public phrase: string = ''
  public isOpen: boolean = false
  public readonly config = SEARCHBAR_POPOVER_CONFIG

  public goToEvent (slug: string): void {
    this.isOpen = false
    this.$nextTick(() => {
      this.$router.replace({
        name: 'events.event',
        params: { slug }
      })
    })
  }

  public goToSearchResultsPage (): void {
    this.isOpen = false
    this.$router.push({
      name: 'events.search-results',
      query: {
        q: this.phrase
      }
    })
  }

  public get slicedEvents (): EventModel[] | null {
    if (!this.events) {
      return null
    }

    return this.events.items.slice(0, 3)
  }

  public onBlur (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_SEARCH)
  }

  protected async searchEventByPhrase (phrase: string): Promise<void> {
    try {
      this.isLoading = true
      this.events = await this.eventsRepository.search(phrase)
    } catch (e) {
      this.errors = e as Record<string, string>
    } finally {
      this.isLoading = false
    }
  }

  protected async searchWithThresholdTimeout (phrase: string): Promise<void> {
    const debouncedSearch = debounce(() => this.searchEventByPhrase(phrase), debounceTimeout)
    return debouncedSearch()
  }

  @Watch('phrase')
  protected async onPhraseChange (newValue: string, oldValue: string): Promise<void> {
    if (newValue === oldValue) {
      return
    }

    if (newValue.length < 3) {
      this.isOpen = false
      return
    }

    this.isOpen = true

    await this.searchWithThresholdTimeout(newValue)
  }
}

export default SearchBar
