import { IResource } from '@/core/general'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const EventResources: IResource = {
  name: 'EventResources',
  prefix: '',
  resources: {
    fetchEvents: {
      url: 'events',
      method: 'GET',
      params: [
        'page_size',
        'search',
        'filter_by_current_city',
        'not_ended',
        'cities',
        'period',
        'categories',
        'page'
      ]
    },
    fetchGroupedEvents: {
      url: 'custom_events_groups/{slug}',
      method: 'GET'
    },
    fetchPromotedEvents: {
      url: 'events/?is_promoted=true',
      method: 'GET',
      params: [
        'page_size',
        'search',
        'filter_by_current_city',
        'not_ended',
        'cities',
        'period',
        'categories',
        'page'
      ]
    },
    fetchEvent: {
      url: 'events/{slug}',
      method: 'GET'
    }
  }
}
