
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Container' })
export class Container extends Vue {
  @Prop({ type: Boolean, required: false })
  public readonly wide?: boolean

  public get containerClass(): string {
    if (this.wide) {
      return 'container-wide'
    }

    return 'container'
  }
}
export default Container
