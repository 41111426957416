
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { CarouselConfig } from './Carousel.contracts'
import { CAROUSEL_DEFAULT_CONFIG } from './Carousel.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Carousel>({
  name: 'Carousel',
  mounted () {
    setTimeout(() => {
      this.boot()
      this.registerNavigationListeners()
    }, 100)
  }
})
export class Carousel extends Vue {
  @Prop({ type: Object, required: false})
  public readonly config!: CarouselConfig

  @Ref('carousel')
  public carouselRef!: any

  // @ts-expect-error
  protected swiper: Swiper | null = null
  protected initialConfig = CAROUSEL_DEFAULT_CONFIG

  protected boot (): void {
    if (typeof window === 'undefined' || this.carouselRef === null) {
      return
    }

    this.$nextTick(() => {
      // @ts-expect-error
      this.swiper = new Swiper(this.carouselRef, {
        ...this.initialConfig,
        ...this.config
      })
    })

    setTimeout(() => {
    if (this.swiper.$wrapperEl) {
      this.swiper.$wrapperEl.css('height', '')
    }
    this.swiper.updateAutoHeight(300)
    }, 300)
  }

  protected registerNavigationListeners (): void {
    if (typeof this.config.navigationButtons === 'undefined') {
      return
    }

    const nextEl = document.querySelector(`${this.config.navigationButtons?.nextEl}`)
    const prevEl = document.querySelector(`${this.config.navigationButtons?.prevEl}`)

    nextEl?.addEventListener('click', () => {
      this.swiper?.slideNext()
    })

    prevEl?.addEventListener('click', () => {
      this.swiper?.slidePrev()
    })
  }
}

export default Carousel
