import { injectable } from 'inversify'

import { ILeafletService, LeafletInstance, MarkerPosition } from './leaflet.contracts'
import { Marker } from './marker'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@injectable()
export class LeafletService implements ILeafletService {
  protected instance: LeafletInstance | null = null
  protected layers: any[] = []

  public addLayer (layerObj: any): void {
    this.setMarker(layerObj)
  }

  /**
   * @inheritDoc
   */
  public init (referenceElement: string, position: MarkerPosition): void {
    if (typeof window !== 'undefined') {
      require('leaflet/dist/leaflet.css')
      require('leaflet')
      require('./plugins/edge-buffer.js')

      // @ts-ignore
      this.instance = L.map(referenceElement).setView([position.latitude, position.longitude], 13)
      // @ts-ignore
      L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
        attribution: '',
        minZoom: 2,
        maxZoom: 20,
        edgeBufferTiles: 1,
      }).addTo(this.instance!);
    }
  }

  public setMarker (marker: Marker): void {
    const [id, latitude, longitude, element, className ] = marker.toArray()
    // @ts-ignore
    const newMarker = L.marker([latitude, longitude], {
      // @ts-ignore
      icon: L.divIcon({
        className: className,
        iconSize: 'auto',
        html: element
      })
    }).on('click', function (e: any) {
      marker.onClick(e)
    })

    this.layers.push({
      id,
      layer: newMarker
    })

    this.instance?.addLayer(newMarker)
  }

  public flyToLocation (position: MarkerPosition): void {
    this.instance!.flyTo([position.latitude, position.longitude], 16)
  }

  public getInstance (): LeafletInstance | null {
    return this.instance
  }

  public removeLayer (layerId: string): void {
    const foundLayer = this.layers.find(layer => layer.id === layerId)

    if (foundLayer) {
      this.instance?.removeLayer(foundLayer.layer)
    }
  }
}