import { RouteConfig } from 'vue-router'
import { defineAsyncComponent } from 'vue'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
const enum RouteName {
  Main = 'main',
  SearchResults = 'searchResults',
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: defineAsyncComponent(() => import('../../../shared/templates/WithFooter.vue')),
    children: [
      {
        path: '',
        name: RouteName.Main,
        component: defineAsyncComponent(() => import('../views/MainView.vue')),
      },
    ]
  },
  {
    path: '/search-results',
    component: defineAsyncComponent(() => import('../../../shared/templates/WithFooter.vue')),
    children: [
      {
        path: '',
        name: RouteName.SearchResults,
        component: defineAsyncComponent(() => import('../views/SearchResults.vue')),
      }
    ]
  }
]