const path = require('path')

export default {
  css: {
    normal: 'inline',
    vue: 'inline'
  },
  plugins: [
    '@uvue/core/plugins/asyncData',
    '@uvue/core/plugins/middlewares',
    '@uvue/core/plugins/errorHandler',
    '@uvue/core/plugins/prefetch',
    // Custom plugins
    // path.join(__dirname, 'src', 'shared', 'plugins', 'init.plugin.ts'),
  ]
}
