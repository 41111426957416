import { AnyObject, IResource, ResourceActionDescription } from '../../general'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const findResourceEntity = (name: string, resources: IResource[]): IResource => {
  let foundResource: IResource | undefined = undefined

  for (const resource of resources) {
    if (resource.name === name) {
      foundResource = resource
    }
  }

  if (!foundResource) {
    throw new Error(`Resource ${name} not found`)
  }

  return foundResource
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const findResourceAction = (actionName: string, resource: IResource): ResourceActionDescription => {
  if (!(Object.keys(resource.resources).includes(actionName))) {
    throw new Error(`Resource action ${actionName} not found`)
  }

  for (const [ key, action ] of Object.entries(resource.resources)) {
    if (key === actionName) {
      return action
    }
  }

  throw new Error(`Resource action ${actionName} not found`)
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const replaceVariables = (str: string, params: AnyObject, queryParams: string[]) => {
  if (!Object.keys(params).length) {
    return {
      url: str,
      filteredParams: {}
    }
  }
  const filteredParams: AnyObject = {}

  for (const key in params) {
    if (queryParams.includes(key)) {
      filteredParams[key] = params[key]
    }

    const pattern = new RegExp(`{${key}}`, 'g')
    str = str.replace(pattern, params[key])
  }

  return {
    url: str,
    filteredParams
  }
}