import Vue from 'vue'
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from '@sentry/integrations'

export const initSentry = (): void => {
  if (process.env.VUE_APP_ENV !== 'production') {
    return
  }

  Sentry.init({
    Vue,
    attachProps: true,
    dsn: process.env.VUE_APP_SENTRY_DSN || '',
    integrations: [new CaptureConsole({ levels: ['error', 'warning'] })],
    logErrors: true,
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV
  })
}