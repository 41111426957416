export default {
    home: 'Home',
    pwaPrompt: {
        install: 'Install App',
        close: 'Close',
    },
    location: 'Location',
    event: {
        dateTime: 'Date and Time',
        location: 'Location',
        price: 'Price',
        category: 'Category',
        description: 'Description',
        buyTicket: 'Buy Ticket',
        showOnMap: 'Show in Maps',
        peopleResponded: 'interested',
    },
    eventsList: {
        title: 'Events'
    },
    searchBar: {
        placeholder: 'Search for event...',
        results: 'Search results',
        notFound: 'No events found for `{phrase}`',
        showAll: 'Show all',
        resultsFor: 'Search resulst for: '
    },
    filtersDrawer: {
        label: 'Filters',
        reset: 'Reset',
        showResults: 'Show results',
        filter: {
            categories: 'Categories',
            date: 'Date',
            location: 'Location'
        },
    },
    contactUs:{
        title: "Contact Us",
        description: "Provide quick explanation of purpose for contacting",
        form: {
            sender: "Email or phone number",
            subject: "Name, nickname, etc..",
            content: "Theme...",
            submit: "Submit"
        },
        isSent: {
            description: "Your request has been successfully sent. Thank you for your time and interest.",
            submit: "Close"
        }
    },
    navbar: {
        contact: 'CONTACT US',
        lastEvents: 'Last Events',
        savedEvents: 'Saved Events',
        search: 'Start writing event data (ex: title)...',
        maps: 'Events map'
    },
    footer: {
        events: {
            title: 'Events',
            children: {
                saved: 'Saved events',
                past: 'Past events'
            }
        },
        contact: {
            title: 'Contact information',
            children: {
                offer: 'I have an offer',
            }
        },
        socials: {
            title: 'Our social media',
            children: {
                instagram: 'Instagram',
                facebook: 'Facebook',
                tiktok: 'TikTok',
            }
        },
        'terms-and-privacy': {
            title: 'Terms & Privacy policy',
            children: {
                'privacy-policy': 'Privacy policy',
            }
        }
    },
    noEventsBanner: {
        textOneTemp: 'Sorry, we couldn\'t find any events around you',
        textOne: 'Sorry, we couldn\'t find any events in ',
        textTwo: 'Please try selecting a different city using the '
    },
    relatedEvents: 'Related Events',
    savedEvents: 'Saved Events',
    save: 'Save',
    collections: 'Picked up Collections',
    events: 'events',
    pastEvents: 'Last Events',
    openInGoogleMaps: "Open in Google Maps",
    open: "Open",
    locale: 'Language',
    readMore: 'Read More',
    loadMore: 'Load More',
    filters: 'Filters',
    locales: {
        EN: 'English',
        RU: 'Русский',
        PL: 'Polski',
    }
}