import { RouteConfig } from 'vue-router'
import { defineAsyncComponent } from 'vue'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const enum RouteName {
  Favourites = 'favourites',
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const routes: Array<RouteConfig> = [
  {
    path: '/my-favourites',
    name: RouteName.Favourites,
    component: defineAsyncComponent(() => import('../views/Favourites.vue' as unknown as string))
  },
]
