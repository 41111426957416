import { ModalRegister } from '@/core/services/modal'

import { FiltersModal } from '../components/organisms/FiltersModal'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export enum EventsModuleModalsCollection {
  FiltersModal = 'filters_modal'
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const eventsModuleModalsRegistry: ModalRegister = {
  [EventsModuleModalsCollection.FiltersModal]: FiltersModal
}