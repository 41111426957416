
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'ImageRenderer' })
export class ImageRenderer extends Vue {
  /**
   * @see ImageProps.src
   */
  @Prop({ type: String, required: false, default: '' })
  public readonly alt!: string

  /**
   * @see ImageProps.src
   */
  @Prop({ type: String, required: true })
  public readonly src!: string

  /**
   * @see ImageProps.src
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly isLazy!: boolean

  public get img (): any {
    return {
      src: this.src,
      preLoad: 2.5,
      attempt: 1,
      error: require('@/assets/images/placeholder.png'),
      loading: require('@/assets/images/placeholder.png'),
    }
  }

}

export default ImageRenderer
