import { Module } from 'vuex'

export const store: Module<any, any> = {
  namespaced: true,

  state: () => ({
    wishlist: null
  }),

  mutations: {
    setWishlist (state, wishlist) {
      state.wishlist = wishlist
    }
  },

  getters: {
    wishlist: state => state.wishlist
  }
}