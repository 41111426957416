import { VueConstructor } from 'vue'

import { dslComponentsRegistry } from './registry'
import { iconsRegistry } from './icons'

export const DSLPlugin = {
  install (_Vue: VueConstructor) {
    for (const [name, component] of Object.entries(dslComponentsRegistry)) {
      _Vue.component(`Ui${name}`, component as Vue)
    }

    _Vue.mixin({
      provide: {
        icons: iconsRegistry
      }
    })
  }
}