import { ModalRegister } from '@/core/services/modal'

import { FiltersDrawer } from '../components/organisms/FiltersDrawer'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export enum EventsModuleDrawersCollection {
  FiltersDrawer = 'filters_drawer'
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const eventsModuleDrawersRegistry: ModalRegister = {
  [EventsModuleDrawersCollection.FiltersDrawer]: FiltersDrawer
}