
import { Component, Prop, Vue } from 'vue-property-decorator'

import { defaultThemeRegistry, Theme } from '../../assets/config'
import { resolveTheme } from '../../helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Card' })
export class Card extends Vue {
  @Prop({ type: String, required: false, default: '' })
  public readonly className?: string

  @Prop({ type: String, required: false, default: Theme.Accent })
  public readonly theme!: Theme

  public get resolvedTheme (): string {
    return resolveTheme(this.theme, defaultThemeRegistry, Theme.Primary)
  }

  public onClick (): void {
    this.$emit('click')
  }
}
export default Card
