import { VueConstructor } from 'vue'

function escapeRegExp (str?: string) {
  return str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const highlight = (_Vue: VueConstructor) => {
  _Vue.directive('highlight', {
    bind (el, binding) {
      const searchTerm = binding?.arg?.toLowerCase()
      const content = binding.value

      const regex = new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi')
      const highlightedContent = content.replace(regex, '<span class="highlight">$1</span>')

      el.innerHTML = highlightedContent
    }
  })
}