
import { Component, Prop } from 'vue-property-decorator'
import { AnyObject } from '@/core/general'

import { FilterAware } from '@/shared/components/molecules/Filter'

@Component<FiltersModal>({
  name: 'FiltersModal',
  mounted (): void {
    this.appliedFiltersData = this.payload?.appliedFilters ?? {}
  }
})
export class FiltersModal extends FilterAware {
  @Prop({ type: Function, required: true })
  public close!: CallableFunction

  @Prop({ type: Object, required: false })
  public payload?: AnyObject

  protected appliedFiltersData: AnyObject = {}

  public get fCategories (): string[] {
    return this.appliedFiltersData?.categories ? this.appliedFiltersData?.categories.split(',') : []
  }

  public set fCategories (categories: string[]) {
    this.appliedFiltersData = {
      ...this.appliedFiltersData,
      categories: categories.join(',')
    }
  }

  public get fCities (): any[] {
    return this.appliedFiltersData?.cities ? this.appliedFiltersData?.cities.split(',') : []
  }

  public set fCities (cities: string[]) {
    this.appliedFiltersData = {
      ...this.appliedFiltersData,
      cities: cities.join(',')
    }
  }

  public get fPeriod (): string {
    return this.appliedFiltersData?.period ?? ''
  }

  public set fPeriod (period: string) {
    this.appliedFiltersData = {
      ...this.appliedFiltersData,
      period
    }
  }

  /**
   * Determines the function to handle the selected filters and fire the onApply event.
   */
  public onApply (): void {
    if (this.payload?.shouldRedirect) {
      this.$router.push({ name: 'events.events', query: this.appliedFiltersData })
    } else {
      this.payload?.onApply(this.appliedFiltersData)
    }

    this.close()
  }

  public flushFilters() {
    this.queryParams = {}
    this.appliedFiltersData = {}
  }
}
export default FiltersModal
