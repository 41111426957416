
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gamil.com>
 */
@Component({ name: 'Textarea' })
export class Textarea extends Vue {
  @Prop({ type: String, required: false, default: '' })
  public readonly placeholder!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly required!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isFluid!: boolean

  @PropSync('model', { type: String, required: true })
  public _model!: string
}
export default Textarea
