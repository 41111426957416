
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Radio' })
export class Radio extends Vue {
  @PropSync('model', { type: [String, Number], required: true })
  public _model!: string | number

  @Prop({ type: [String, Number], required: true })
  public value!: string | number

  @Prop({ type: String, required: false })
  public label?: string
}

export default Radio
