/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const resolveTheme = <T extends string>(
  theme: T,
  themeRegistry: Record<T, string>,
  defaults: string
): string | T => {

  if (theme in themeRegistry) {
    return themeRegistry[theme]
  }

  return themeRegistry[defaults as T]
}