import { MappingSkeleton } from '@/core/general'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const eventMappingSkeleton: MappingSkeleton = {
  id: 'id',
  title: 'title',
  categories: 'categories',
  description: 'description',
  endDate: 'end_date',
  dateLabel: 'date_label',
  startDate: 'start_date',
  facebookUrl: 'facebook_url',
  imageUrl: 'image_url',
  ticketUrl: 'ticket_url',
  ticketPrice: 'ticket_price',
  responses: 'people_responded',
  keywords: 'keywords',
  organizer: 'organizer',
  locationLabel: 'location_label',
  metaDescription: 'meta_description',
  isPromoted: 'is_promoted',
  googleMapsLink: 'google_maps_link',
  peopleResponded: 'people_responded',
  images: (item) => {
    if (item.custom_event_images && Array.isArray(item.custom_event_images) && item.custom_event_images.length > 0) {
      return item.custom_event_images
    }

    return item.scraped_images
  },
  location: (item) => {
    if (item.google_location) {
      return {
        name: item.google_location.name,
        address: item.google_location.formatted_address,
      }
    }

    return null
  },
  geo: (item) => {
    if (item.google_location && item.google_location.geometry) {
      return {
        latitude: item.google_location.geometry.location.lat,
        longitude: item.google_location.geometry.location.lng,
      }
    }

    return null
  },
  slug: 'slug'
}
