
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ModalSize, modalSizeRegistry } from './ModalInner.contracts'

@Component({ name: 'ModalInner' })
export class ModalInner extends Vue {
  @Prop({ type: Function, required: true })
  public close!: CallableFunction

  @Prop({ type: String, required: false, default: ModalSize.Auto })
  public size?: ModalSize

  public get sizeClass (): string {
    if (!this.size) {
      return ModalSize.Auto
    }

    return modalSizeRegistry[this.size]
  }
}

export default ModalInner
