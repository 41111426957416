
import { Component, Vue } from 'vue-property-decorator'
import { Subscription } from 'rxjs'
import { VueConstructor } from 'vue'

import { IModal, ModalState, ModalServiceType, ModalOptions } from '@/core/services/modal'
import { AnyObject } from '@/core/general'

@Component<Modal>({
  name: 'Modal',
  mounted (): void {
    this.subscription = this.modalService.state$.subscribe((state: ModalState) => {
      this.state = state
    })
  },
  beforeDestroy (): void {
    this.subscription?.unsubscribe()
  }
})
export class Modal extends Vue {
  protected readonly modalService = this.$container.get<IModal>(ModalServiceType)

  protected state: ModalState = {}

  protected subscription: Subscription | null = null

  public get component (): VueConstructor {
    if (this.state.modal) {
      return this.state.modal
    }

    throw new Error('No modal component found in state!')
  }

  public get isOpen (): boolean {
    return this.state.open ?? false
  }

  public get payload (): AnyObject | undefined {
    return this.state.payload
  }

  public get options (): ModalOptions | undefined {
    return this.state.options
  }

  public close (): void {
    this.modalService.close()
  }
}
export default Modal
