import { AnyObject } from '@/core/general'

/**
 * @author Javlon Khalimjanov <khalimjanov2000@gmail.com>
 */
export interface IAmplitude {
  /**
   * @param eventName - name of the tracked event.
   * @param payload - bofy of the event.
   */
  emit(eventName: AppAmplitudeEvent, payload?: AnyObject): void
}

/**
 * @author Javlon Khalimjanov <khalimjanov2000@gmail.com>
 */
export enum AppAmplitudeEvent {
  ON_SESSION_START = 'SESSION_START',
  ON_LAUNCH_FIRST_TIME = 'LAUNCH_FIRST_TIME',
  ON_MAIN_PAGE = 'Open_Main_Page',
  ON_EVENT_PAGE = 'Open_Event',
  ON_MAP_PAGE = 'Open_map_page',
  ON_EVENT_ON_MAP = 'Show_in_map',
  ON_EVENT_SELECT_ON_MAP = 'ON_EVENT_SELECT_ON_MAP',
  ON_OPEN_EVENT_FROM_MAP = 'Open_event_located_on_map',
  ON_SHOW_ON_GOOGLE_MAPS = 'Show_in_google_maps',
  ON_OPEN_CHANGE_LANGUAGE = 'Open_change_language',
  ON_OPEN_SEARCH = 'Open_search',
  ON_CLICK_LOGO = 'Click_logo',
  ON_OPEN_LAST_EVENTS = 'Open_last_events',
  ON_OPEN_SAVED_EVENTS = 'Open_saved_events',
  ON_OPEN_FILTERS = 'Open_filters',
  ON_APPLY_FILTERS = 'Filters_applied',
  ON_SAVE_EVENT = 'Save_event',
  ON_BUY_TICKET = 'Buy_ticket',
  ON_SHARE_EVENT = 'Share_event',
  ON_CATEGORY_APPLIED_IN_MAP = 'Apply_categories_on_map'
}

export const AmplitudeType = Symbol.for('IAmplitude')