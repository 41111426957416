
import { Component, Prop, Vue } from 'vue-property-decorator'

import { defaultThemeRegistry, Theme } from '../../assets/config'

import { resolveTheme } from '../../helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'IconButton' })
export class IconButton extends Vue {
  @Prop({ type: String, required: true })
  public readonly icon!: string

  @Prop({ type: String, required: false })
  public readonly color?: string

  @Prop({ type: String, required: false, default: Theme.Primary })
  protected readonly theme!: Theme

  /**
   * Determines theme of the icon button.
   */
  public get resolvedTheme(): string {
    return resolveTheme(this.theme, defaultThemeRegistry, Theme.Primary)
  }

  public onClick (): void {
    this.$emit('click')
  }
}
export default IconButton
