import { AnyObject, Pageable } from '@/core/general'

import { EventModel, EventsGroupModel } from '../models'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface IEventsRepository {
  fetchEvents (page: number, pageSize?: number, query?: AnyObject): Promise<Pageable<EventModel>>
  fetchEvent (slug: string): Promise<EventModel>
  fetchGroupedEvents (slug?: string): Promise<EventsGroupModel>
  fetchGroupedEventsCollection (slug?: string): Promise<EventsGroupModel[]>
  fetchPromotedEvents (): Promise<EventModel[]>
  search (phrase: string, pageSize: number): Promise<Pageable<EventModel>>
}
export const EventsRepositoryType = Symbol.for('EventsRepository')