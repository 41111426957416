
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({ name: 'Check' })
export class Check extends Vue {
  @PropSync('model', { type: [String, Number, Array], required: true })
  public _model!: string | number | Array<any>

  @Prop({ type: String, required: false })
  public readonly label!: string

  @Prop({ type: [String, Number], required: false, default: null })
  public readonly value!: string | number

  public get checkboxId (): string {
    return `check-${Math.random().toString(36).substr(2, 9)}`
  }
}
export default Check
