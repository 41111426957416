
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'Heading'
})
export class Heading extends Vue {
  @Prop({ type: Number, required: false, default: 1 })
  protected readonly level!: string

  public get component (): string {
    return `h${this.level}`
  }
}
export default Heading
