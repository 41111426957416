import { CanHaveTheme, CanHaveThemeStyle } from '../../assets/config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ButtonProps extends CanHaveTheme<Theme>, CanHaveThemeStyle {
  /**
   * Determines the type of the form element
   */
  type: string

  /**
   * Determines whether the form element is readonly
   */
  readonly: boolean

  /**
   * Determines whether the form element is disabled
   */
  disabled: boolean

  /**
   * Determines whether button should have the 100% of the width.
   */
  isFluid: boolean

  /**
   * Determines the button variant.
   */
  variant: Variant
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum Theme {
  Primary = 'primary',
  Secondary = 'secondary',
  White = 'white',
  Transparent = 'transparent',
}

export enum Variant {
  Default = 'default',
  Bordered = 'bordered'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const buttonThemeRegistry: Record<Theme, string> = {
  [Theme.Primary]: '--primary',
  [Theme.Secondary]: '--secondary',
  [Theme.White]: '--white',
  [Theme.Transparent]: '--transparent'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const buttonVariantRegistry: Record<Variant, string> = {
  [Variant.Default]: '',
  [Variant.Bordered]: '--bordered'
}