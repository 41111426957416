export default {
  pwaPrompt: {
    install: 'Установить',
    close: 'Закрыть',
  },
  home: 'Главная страница',
  event: {
    dateTime: 'Дата и время',
    location: 'Местоположеине',
    price: 'Цена',
    category: 'Категория',
    description: 'Описание',
    buyTicket: 'Купить Билет',
    showOnMap: 'Показать на карта',
    peopleResponded: 'заинтересовано людей',
  },
  eventsList: {
    title: 'События'
  },
  location: 'Местоположение',
  searchBar: {
    placeholder: 'Искать...',
    results: 'Результаты поиска',
    notFound: 'Ничего не найдено для `{phrase}`',
    showAll: 'Показать все',
    resultsFor: 'Результаты поиска по запросу: '
  },
  contactUs:{
    title: "Связаться с нами",
    description: "Если есть предложения или нужно выложить событие",
    form: {
      sender: "Почта или телеграм",
      subject: "Имя",
      content: "Тема",
      submit: "ОТПРАВИТЬ"
    },
    isSent: {
      description: "Заявка принята, скоро свяжемся!",
      submit: "ХОРОШО"
    }
  },
  filtersDrawer: {
    label: 'Фильтры',
    reset: 'Сброс',
    showResults: 'Показать результаты',
    filter: {
      categories: 'Категория',
      date: 'Дата',
      location: 'Местоположение'
    }
  },
  navbar: {
    contact: 'СВЯЗАТЬСЯ',
    lastEvents: 'Прошлые события',
    savedEvents: 'Избранные события',
    search: 'Навзвание мероприятия',
    maps: 'Карта событий'
  },
  footer: {
    events: {
      title: 'События',
      children: {
        saved: 'Избранные события',
        past: 'Прошедшие события'
      }
    },
    contact: {
      title: 'Связаться с нами',
      children: {
        offer: 'Есть предложение',
      }
    },
    socials: {
      title: 'Наши социальные сети',
      children: {
        instagram: 'Instagram',
        facebook: 'Facebook',
        tiktok: 'TikTok',
      }
    },
    'terms-and-privacy': {
      title: 'Политика приватности и Условия',
      children: {
        'privacy-policy': 'Политика приватности',
      }
    }
  },
  noEventsBanner: {
    textOneTemp: 'Извините, не удалось найти мероприятий для указанного города',
    textOne: 'Извините, не удалось найти мероприятий для ',
    textTwo: 'Попробуйте выбрать другой город с помощью '
  },
  relatedEvents: 'Похожие события',
  savedEvents: 'Избранные события',
  pastEvents: 'Прошлые события',
  openInGoogleMaps: "Показать в Google Maps",
  open: "Перейти",
  save: 'Сохранить',
  collections: 'Подборки',
  events: 'событий',
  locale: 'Язык',
  readMore: 'Подробнее',
  loadMore: 'Показать больше',
  filters: 'Фильтры',
  locales: {
    EN: 'English',
    RU: 'Русский',
    PL: 'Polski'
  }
}