import { injectable } from 'inversify'

import { AnyObject } from '@/core/general'
import { IHttpConnector } from '@/core/connectors/http'

import { LocaleKey } from '@/shared'

import { IRootService } from './contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@injectable()
export class RootService implements IRootService {
  protected connector: IHttpConnector
  protected properties: AnyObject = {}

  protected locale: LocaleKey | null = null

  constructor (connector: IHttpConnector) {
    this.connector = connector
  }

  /**
   * @inheritDoc
   */
  public async init (browserIp: string): Promise<void> {
    try {
      const response = await this.connector.call('RootResources', 'init', {}, { browser_ip: browserIp })
      const {
        filters,
        languages,
        default_language,
        selected_language,
        geo,
        city_choices,
        map_filters,
        google_auth_start_url,
        facebook_auth_start_url,
        meta
      } = response.data

      this.setProperty('filters', filters)
      this.setProperty('languages', languages)
      this.setProperty('location', geo)
      this.setProperty('city', geo.city)
      this.setProperty('city_choices', city_choices)
      this.setProperty('map_filters', map_filters)
      this.setProperty('google_auth_start_url', google_auth_start_url)
      this.setProperty('facebook_auth_start_url', facebook_auth_start_url)
      this.setProperty('meta', meta)

      this.locale = selected_language ?? default_language
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

  /**
   * @inheritDoc
   */
  public setProperty<T extends any = any> (name: string, value: T): void {
    this.properties = {
      ...this.properties,
      [name]: value
    }
  }

  /**
   * @inheritDoc
   */
  public setLocale (key: LocaleKey): void {
    this.locale = key
    document.cookie = `lang=${key};path=/;expires=Tue, 19 Jan 2038 04:14:07 GMT`
  }

  /**
   * @inheritDoc
   */
  public getLocale (): LocaleKey {
    return this.locale ?? LocaleKey.EN
  }

  /**
   * @inheritDoc
   */
  public getProperty<T extends any = any> (name: string): T {
    return this.properties[name]
  }

  /**
   * @inheritDoc
   */
  public async sendMail (sender: string, subject: string, content: string): Promise<void> {
    const response = await this.connector
      .call('RootResources', 'sendMail', {}, { sender, subject, content })
  }
}
