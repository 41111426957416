
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@/core/general'

@Component({ name: 'FilterBadge' })
export class FilterBadge extends Vue {
  @Prop({ type: Object, required: true })
  public readonly filter!: Record<string, AnyObject>

  public onDismiss (): void {
    this.$emit('remove', this.filter.key)
  }
}
export default FilterBadge
