
import { Component, Inject, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { Locale, LocaleKey } from '@/shared'

import { IRootService, RootServiceType } from '@/modules/root/services/root'

import { NavbarMobile, NavbarDesktop } from './versions'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Navbar' })
export class Navbar extends Vue {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  protected readonly rootService: IRootService = this.$container.get(RootServiceType)

  public get locales (): Locale {
    return this.rootService.getProperty('languages')
  }

  public get selectedLocale (): LocaleKey {
    return this.rootService.getLocale()
  }

  public get version (): VueConstructor {
    return this.isMobile() ? NavbarMobile : NavbarDesktop
  }

  public changeLocale (key: LocaleKey): void {
    this.rootService.setLocale(key)
  }
}
export default Navbar
