/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface IWishlistService {
  add<I> (event: WishlistItem<I>): void
  initBucket (): void
  hasItemById (id: number): boolean
  remove<I> (event: WishlistItem<I>): void
  removeById (id: number): void
  retrieveItems<I> (): WishlistItem<I>[]
  retrieve (bucketKey?: string): WishlistBucket | null
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface WishlistBucket<I extends any = any> {
  items: WishlistItem<I>[]
  count: number
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export type WishlistItem<I> = WishlistPrimitiveItem<I>

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface WishlistPrimitiveItem<I> {
  id: number
  validUntil: string
  item: I
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const WishlistBucketKey = 'wishlist'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export const WishlistServiceType = Symbol.for('IWishlistService')