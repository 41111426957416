import { format } from 'date-fns'

import { Model } from '@/core/general/model'
import { AnyObject, Pageable } from '@/core/general'

import { MarkerPosition } from '@/shared/services/leaflet'
import { KeyLabel, LocaleKey } from '@/shared'
import { pl, enGB, ru } from 'date-fns/locale'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface EventLocation {
  name: string,
  address: string
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface Event {
  id: number
  title: string
  categories: KeyLabel[]
  description: string
  endDate?: string
  dateLabel: string
  startDate: string
  rawLocation: string
  images: AnyObject[]
  organizer: string
  response: string | null
  ticketUrl: string | null
  ticketPrice: string | null
  locationLabel: string | null
  metaDescription: string
  slug: string
  isPromoted?: boolean
  location: EventLocation | null
  geo: MarkerPosition | null
  googleMapsLink: string
  peopleResponded: number | null
  keywords: string
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export interface IEvent {
  /**
   * Determines the first category that event belongs to.
   */
  category: () => KeyLabel

  /**
   * Extracts the geolocation of the event in [lng, lat] format.
   */
  extractGeo: () => number[]

  /**
   * Formats date if present to human-readable format
   */
  formattedDate: (locale: LocaleKey) => string | undefined
}

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
export class EventModel extends Model<Event> implements IEvent {
  /**
   * @inheritDoc
   */
  public category (): KeyLabel {
    return this.properties['categories'][0]
  }

  /**
   * @inheritDoc
   */
  public extractGeo (): number [] {
    return [this.properties['geo']?.latitude ?? 0, this.properties['geo']?.longitude ?? 0]
  }

  /**
   * @inheritDoc
   */
  public formattedDate (locale: LocaleKey): string | undefined {
    //
    const i18nToDateFnsLocaleMap = {
      [LocaleKey.PL.toLowerCase()]: pl,
      [LocaleKey.EN.toLowerCase()]: enGB,
      [LocaleKey.RU.toLowerCase()]: ru
    }

    let date

    if (this.properties['startDate']) {
      date = format(new Date(this.properties['startDate']), 'dd LLLL, HH:mm', { locale: i18nToDateFnsLocaleMap[locale] })
    }

    if (this.properties['endDate']) {
      return date + ' - ' + format(new Date(this.properties['endDate']), 'dd LLLL, HH:mm', { locale: i18nToDateFnsLocaleMap[locale] })
    }

    return date
  }
}
